import React, { useState, useEffect } from "react"
import Fuse from "fuse.js"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import SearchIcon from "@material-ui/icons/Search"

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16,
    background: "#424041",
  },
  result: {
    fontSize: 18,
    cursor: "pointer",

    "&:hover": {
      background: "#fef699",
    },
  },
}))

const AbbreviationsSearch = ({ items, onResultClick }) => {
  const classes = useStyles()

  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])

  const fuse = new Fuse(items, {
    includeScore: true,
    threshold: 0.3,
    keys: ["match"],
    shouldSort: false,
  })

  useEffect(() => {
    setResults([...fuse.search(query).map(r => r.item)])
  }, [query])
  
  const onSearch = e => {
    setQuery(e.target.value)
  }

  const getColorizedString = str => {
    return str.replace(
      new RegExp(`(${query})`, "ig"),
      `<span style="background-color: #fef699">$1</span>`
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          label="Caută abreviere"
          fullWidth
          value={query}
          autoFocus
          onChange={onSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  aria-label="toggle password visibility"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {results.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {results.map(result => (
              <Grid
                item
                xs={12}
                key={result.id}
                onClick={() => onResultClick(result)}
              >
                <Typography
                  className={classes.result}
                  dangerouslySetInnerHTML={{
                    __html: getColorizedString(result.title),
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default AbbreviationsSearch
