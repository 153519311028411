import React from "react"

import makeStyles from '@material-ui/core/styles/makeStyles'

import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
  },
  letters: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  letter: {
    cursor: "pointer",
    fontWeight: 700,
    fontSize: 20,
    color: "#525154",

    "&:hover": {
      background: "#fef699",
    },
  },
}))

const AbbreviationsAlphabet = ({ items, onLetterClick }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.wrapper}
    >
      <Grid item xs={12}>
        <div className={classes.letters}>
          {items.map(item => (
            <div
              className={classes.letter}
              onKeyDown={() => onLetterClick(item)}
              tabIndex={item.idx}
              onClick={() => onLetterClick(item)}
              role="button"
              key={item.letter}
            >
              {item.letter}
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  )
}

export default AbbreviationsAlphabet
