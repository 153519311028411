/* eslint-disable no-return-assign */
import React, { useContext, useRef, useEffect } from "react"
import PropTypes from "prop-types"

import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import { groupBy } from "lodash"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import AbbreviationsSearch from "../components/search/AbbreviationsSearch"
import AbbreviationsAlphabet from "../components/abbreviations/AbbreviationsAlphabet"

const useStyles = makeStyles(theme => ({
  date: {
    color: theme.palette.gray,
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },

  abbreviationTitle: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    fontWeight: 700,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 30,
    },
  },
  abbreviationDescription: {
    textAlign: "justify",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 70,
    },
  },
}))

const AbbreviationsPage = props => {
  const {
    data: { abbreviationsPage, abbreviations },
    pageContext: { slug, locale, breadcrumbs },
  } = props

  const classes = useStyles()
  const abbreviationsRef = useRef([])
  const lettersRef = useRef([])
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const abbreviationsList = abbreviations.edges
    .map(r => r.node)
    .filter(a => a.data.matching_words)

  const items = groupBy(abbreviationsList, a => a.data.matching_words[0].toUpperCase())

  const abbreviationsGroups = Object.keys(items).map(k => ({
    letter: k,
    abbreviations: items[k],
  }))

  const schema = abbreviationsPage.data.schema_json.text || ""
  const ogp = abbreviationsPage.data.ogp_tags || ""
  const meta = {
    title: abbreviationsPage.data.meta_title,
    description: abbreviationsPage.data.meta_description,
    keywords: abbreviationsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  useEffect(() => {
    abbreviationsRef.current = abbreviationsRef.current.slice(
      0,
      abbreviationsList.length
    )

    lettersRef.current = lettersRef.current.slice(0, abbreviationsGroups.length)
  }, [abbreviationsList, abbreviationsGroups])

  let i = 0
  let j = 0
  abbreviationsGroups.forEach(abbreviationsGroup => {
    abbreviationsGroup.idx = j
    abbreviationsGroup.abbreviations.forEach(abbreviation => {
      abbreviation.idx = i
      i += 1

      return abbreviation
    })
    j += 1
  })

  const searchItems = abbreviationsList.map(abbreviation => ({
    match: `${abbreviation.data.matching_words} ${abbreviation.data.title.text}`,
    title: abbreviation.data.title.text,
    id: abbreviation.id,
    data: abbreviation,
  }))

  const onSearchResultClick = result => {
    const el = abbreviationsRef.current[result.data.idx]

    el.scrollIntoView({
      behavior: "smooth",
    })
  }

  const onLetterClick = item => {
    const el = lettersRef.current[item.idx]

    el.scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {abbreviationsPage.data.title}
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                  <AbbreviationsSearch
                    items={searchItems}
                    onResultClick={onSearchResultClick}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ height: 75 }}>
                  <AbbreviationsAlphabet
                    items={abbreviationsGroups}
                    onLetterClick={onLetterClick}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                {abbreviationsGroups.map(abbreviationsGroup => (
                  <Grid
                    item
                    ref={el =>
                      (lettersRef.current[abbreviationsGroup.idx] = el)
                    }
                    key={abbreviationsGroup.letter}
                    xs={12}
                  >

                    <SectionTitle component="h2">
                      <span style={{ fontWeight: 700 }}>
                        {abbreviationsGroup.letter}
                      </span>
                    </SectionTitle>
                    <br />
                    <Grid container spacing={4}>
                      {abbreviationsGroup.abbreviations.map(abbreviation => (
                        <Grid
                          item
                          sm={12}
                          key={abbreviation.id}
                          ref={el =>
                            (abbreviationsRef.current[abbreviation.idx] = el)
                          }
                        >
                          <br />
                          <br />
                          <Grid container>
                            <Grid
                              item
                              sm={12}
                              className={classes.abbreviationTitle}
                            >
                              <Link
                                to={`${localizedSlugPrefix(
                                  abbreviation
                                )}/${slug}/${abbreviation.uid}/`}
                              >
                                {abbreviation.data.matching_words} -{" "}
                                {abbreviation.data.long_form}
                              </Link>
                            </Grid>
                            <Grid
                              item
                              sm={12}
                              className={classes.abbreviationDescription}
                            >
                              {abbreviation.data.description.text}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default AbbreviationsPage

export const query = graphql`
  query AbbreviationsPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    abbreviationsPage: prismicAbbreviationsPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      id
      data {
        title
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
      lang
    }

    abbreviations: allPrismicAbbreviation(
      sort: { fields: data___title___text, order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            description {
              text
            }
            matching_words
            long_form
          }
          uid
          lang
        }
      }
    }
  }
`

AbbreviationsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    abbreviationsPage: PropTypes.object.isRequired,
    abbreviations: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
}
